import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { persistor } from "../../../setup/store";

import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import createDistributorTheme from "../../../themes/distributorTheme";
import mainTheme from "../../../themes/mainTheme";

const Header = () => {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const logout = () => {
    persistor.purge();
    dispatch({ type: "SET_USER", payload: undefined });
    dispatch({ type: "SET_ACCESS_TOKEN", payload: undefined });
    dispatch({ type: "SET_EXTERNAL_ID", payload: undefined });
  };

  const { primary = "#8888AA", secondary = "#DDDDDD" } = user?.brand_colours ? JSON.parse(user.brand_colours) : {};

  const mergedTheme = createTheme(mainTheme, createDistributorTheme(primary, secondary));

  return (

    <ThemeProvider theme={mergedTheme}>
      <AppBar
        position="static"
        elevation={0}
        color="transparent"
        enableColorOnDark
      >
        <Container sx={{ py: 2, }} maxWidth="xl">
          <Toolbar disableGutters sx={{}} >

            <a href={"/"}>
              <img
                className="app_logo"
                src={
                  user && user.brand_id ? `/assets/${user.brand_id}_logo.webp`
                    : "" // Empty string for no logo
                }
                alt={
                  user &&
                    user.brand_id
                    ? "logo"
                    : "" // Empty string for no alt text
                }
              />
            </a>

            {user && Object.keys(user).length !== 0 && (
              <>
                <Box
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                >
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {[
                      <MenuItem onClick={handleCloseNavMenu} key="rica-item">
                        <a
                          href="/rica"
                          key="rica"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                        >
                          RICA
                        </a>
                      </MenuItem>,

                      // <MenuItem onClick={handleCloseNavMenu} key="self-rica">
                      //   <a
                      //     href="/self-rica"
                      //     key="self-rica"
                      //     style={{
                      //       my: 2,
                      //       color: "#002846",
                      //       display: "block",
                      //       textTransform: "none",
                      //       textDecoration: "none",
                      //     }}
                      //   >
                      //     Self Rica
                      //   </a>
                      // </MenuItem>,


                      <MenuItem
                        onClick={handleCloseNavMenu}
                        key="sales-report-item"
                      >
                        <a
                          href="/sales-report"
                          key="sales-report"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                        >
                          Sales Report
                        </a>
                      </MenuItem>,
                      <MenuItem
                        onClick={handleCloseNavMenu}
                        key="how-to-videos-item"
                      >
                        <a
                          href="https://support.megsapp.com/en_us/categories/how-to-videos-S1_rXGEWh"
                          target={"_blank"}
                          key="how-to-videos"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                          rel="noreferrer"
                        >
                          How-to videos
                        </a>
                      </MenuItem>,
                      <MenuItem
                        onClick={handleCloseNavMenu}
                        key="knowledge-base-item"
                      >
                        <a
                          href="https://support.megsapp.com/"
                          target={"_blank"}
                          key="knowledge-base"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                          rel="noreferrer"
                        >
                          Knowledge Base
                        </a>
                      </MenuItem>,
                      <MenuItem
                        onClick={handleCloseNavMenu}
                        key="cheatsheet-item"
                      >
                        <a
                          href="/assets/cheatsheet.pdf"
                          target={"_blank"}
                          key="cheatsheet"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                        >
                          Cheatsheet
                        </a>
                      </MenuItem>,
                      <MenuItem
                        onClick={handleCloseNavMenu}
                        key="training-guide-item"
                      >
                        <a
                          href="/assets/training_guide.pdf"
                          target={"_blank"}
                          key="training-guide"
                          style={{
                            my: 2,
                            color: "#002846",
                            display: "block",
                            textTransform: "none",
                            textDecoration: "none",
                          }}
                        >
                          Training Guide
                        </a>
                      </MenuItem>,
                      // Becoming a Honey Connect Specialist
                      user &&
                      user.brand_id === "honeyconnect" && (
                        <MenuItem
                          onClick={handleCloseNavMenu}
                          key="honey-connect-specialist-item"
                        >
                          <a
                            href="/assets/Becoming-a-Honey-Connect-Specialist-A-Comprehensive-Guide-for-Honey-Accessories-Representatives.pdf"
                            target={"_blank"}
                            key="becoming-a-honey-connect-specialist"
                            style={{
                              my: 2,
                              color: "#002846",
                              display: "block",
                              textTransform: "none",
                              textDecoration: "none",
                            }}
                          >
                            Honey Connect Specialist
                          </a>
                        </MenuItem>
                      ),

                      <MenuItem onClick={handleCloseNavMenu} key="logout">
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <Button
                            onClick={logout}
                            color={"secondary"}
                            variant="contained"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                            }}
                            endIcon={<ArrowRightIcon />}
                          >
                            Logout
                          </Button>
                        </Box>
                      </MenuItem>,
                    ]}
                  </Menu>
                </Box>

                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    underline="none"
                    to="/rica"
                    component={RouterLink}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    Rica
                  </Link>
                  {/* <Link
                      underline="none"
                      to="/self-rica"
                      component={RouterLink}
                      sx={{
                        mx: 1,
                        px: 1,
                        fontWeight: 700,
                        fontSize: "1rem",
                        color: "secondary",
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      Self Rica
                    </Link> */}
                  <Link
                    underline="none"
                    to="/sales-report"
                    component={RouterLink}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    Sales Report
                  </Link>
                  <Link
                    underline="none"
                    href="https://support.megsapp.com/en_us/categories/how-to-videos-S1_rXGEWh"
                    target={"_blank"}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    How-to videos
                  </Link>
                  <Link
                    underline="none"
                    href="https://support.megsapp.com/"
                    target={"_blank"}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    Knowledge Base
                  </Link>
                  <Link
                    underline="none"
                    href="/assets/cheatsheet.pdf"
                    target={"_blank"}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    Cheatsheet
                  </Link>
                  <Link
                    underline="none"
                    href="/assets/training_guide.pdf"
                    target={"_blank"}
                    sx={{
                      mx: 1,
                      px: 1,
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: "secondary",
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    Training Guide
                  </Link>
                  {user &&
                    user.brand_id === "honeyconnect" && (
                      <Link
                        underline="none"
                        href="/assets/Becoming-a-Honey-Connect-Specialist-A-Comprehensive-Guide-for-Honey-Accessories-Representatives.pdf"
                        target={"_blank"}
                        sx={{
                          mx: 1,
                          px: 1,
                          fontWeight: 700,
                          fontSize: "1rem",
                          color: "secondary",
                          display: "block",
                          textTransform: "none",
                        }}
                      >
                        Honey Connect Specialist
                      </Link>
                    )}
                  <Button
                    onClick={logout}
                    color="secondary"
                    variant="contained"
                    sx={{
                      textTransform: "initial",
                      fontSize: "1rem",
                      mx: 2,
                      px: 3,
                    }}
                    endIcon={<ArrowRightIcon />}
                  >
                    Logout
                  </Button>
                </Box>

                <Box
                  sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="secondary"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
