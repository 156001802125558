// Utility function to convert a hex color to its RGB components
export const hexToRgb = (hex) => {
  // Use a regular expression to extract the red, green, and blue components
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  // If the hex color is valid, convert it to RGB
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

// Utility function to convert a hex color to its HSL components
export const hexToHSL = (hex) => {
  // First, convert the hex color to RGB
  let { r, g, b } = hexToRgb(hex);
  // Normalize the RGB values
  r /= 255;
  g /= 255;
  b /= 255;

  // Find the maximum and minimum RGB components
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  // Calculate lightness
  let h, s, l = (max + min) / 2;

  // Calculate saturation and hue
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    // Calculate hue
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  // Convert to 0-360 for hue, 0-100 for saturation and lightness
  return { h: h * 360, s: s * 100, l: l * 100 };
};

// Utility function to convert HSL components to a hex color
export const hslToHex = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return `#${((1 << 24) + (Math.round(r * 255) << 16) + (Math.round(g * 255) << 8) + Math.round(b * 255)).toString(16).slice(1)}`;
};

// Utility function to adjust the lightness of a hex color in HSL space
export const adjustLuminosityInHSL = (hex, luminosityFactor) => {
  // Convert the hex color to HSL
  let { h, s, l } = hexToHSL(hex);
  // Adjust the lightness value based on the luminosity factor
  l = Math.min(100, Math.max(0, l + (l * luminosityFactor)));
  // Convert the modified HSL back to hex
  return hslToHex(h / 360, s / 100, l / 100);
};

// Function to generate a color palette based on a main color
export const generatePalette = (mainColor) => {
  // Use the utility function to adjust luminosity for other colors
  return {
    main: mainColor,
    light: adjustLuminosityInHSL(mainColor, 0.2), // Lighten by 20%
    lighter: adjustLuminosityInHSL(mainColor, 0.4), // Lighten by 40%
    dark: adjustLuminosityInHSL(mainColor, -0.2), // Darken by 20%
    darker: adjustLuminosityInHSL(mainColor, -0.4), // Darken by 40%
    contrastText: '#231f20', // Set the contrast text color
  };
};