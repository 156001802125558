import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { login } from "./actions";

import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MuiAlert from "@mui/material/Alert";

import axios from "axios";

import { ThemeProvider } from "@mui/material/styles";
import mainTheme from "../../themes/mainTheme";

window.Buffer = window.Buffer || require("buffer").Buffer;

const API_USERNAME = process.env.REACT_APP_API_USERNAME;
const API_PASSWORD = process.env.REACT_APP_API_PASSWORD;
const API_DECRYPT_KEY = process.env.REACT_APP_API_KEY;
const HQ_PORTAL_URL = process.env.REACT_APP_HQ_PORTAL_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Auth = () => {
  const dispatch = useDispatch();

  const { external_id } = useSelector(
    (state) => ({
      external_id: state.auth.external_id,
    }),
    shallowEqual
  );

  const [loading, setLoading] = useState(true);
  const [is_loading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [severitySnack, setSeveritySnack] = useState("warning");
  const [messageSnack, setMessageSnack] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [web_state, setWebState] = useState(true);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let encrypted_value = query.get("e");
  let iv = query.get("i");
  const partner = query.get("partner");

  useEffect(() => {
    (async () => {
      if (!!web_state) {
        if (encrypted_value && iv && partner) {
          let brand_id = partner;
          if(partner === 'honey') {
            brand_id = 'honeyconnect'

            // navigate to HQ Portal for HoneyConnect with SSO
            window.location.href = `${HQ_PORTAL_URL}/auth/sso?e=${encrypted_value}&i=${iv}&partner=${brand_id}`;
            return;
          }
          const payload = {
            encrypted_value,
            iv,
            partner : brand_id,
          };

          const config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.megsapp.com/api/v1/app/sso/decrypt",
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          };

          const { data } = await axios(config);

          if (data?.status_code === 1001) {
            setLoading(true);
            dispatch({ type: "SET_EXTERNAL_ID", payload: data?.data.external_id });
            await login(API_USERNAME, API_PASSWORD, partner, dispatch);
            setLoading(false);
          } else if (data?.status_code === 9001) {
            setError(data?.message);
          } else {
            setError(data?.message);
          }
        } else {
          setUsername("");
          setPassword("");
          setLoading(false);
        }
      }
    })();
  }, []);

  const handleCloseSnack = (event, reason) => {
    setOpenSnack(false);
  };

  const handleUsernameInput = async (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordInput = async (event) => {
    setPassword(event.target.value);
  };

  const submitForm = async () => {
    if (!username) {
      setError("Please enter a username");
      return;
    } else if (!password) {
      setError("Please enter a password");
      return;
    } else {
      setIsLoading(true);
      let response = await login(username, password, null, dispatch);
      if (response.status_code !== 1001) {
        setIsLoading(false);
        setMessageSnack("Incorrect Username or Password provided");
        setOpenSnack(true);
      }
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Card
          sx={{ width: "85%", pt: 8, pb: 8, mb: 3, p: 4, borderRadius: "1rem" }}
        >
          {loading ? (
            <Box>
              <CircularProgress color="primary" />
            </Box>
          ) : !!web_state ? (
            <>
              <Typography
                variant="h6"
                gutterBottom
                align="left"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#002846",
                  textAlign: "left",
                  pb: 1,
                  pt: 2,
                }}
              >
                Login Credentials
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={error.length !== 0 ? true : false}
                    helperText={error}
                    required
                    id="username"
                    name="username"
                    label="Username"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                      onChange={handleUsernameInput}
                    value={username}
                      sx={{
                        "input": { color: "#002846", fontFamily: "Open Sans" },
                        "& .MuiInputLabel-root": { color: "#002846", fontFamily: "Open Sans", fontWeight: 700 },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { border: "2px solid #002846" },
                          "&:hover fieldset": { border: "2px solid #002846" },
                          "&.Mui-focused fieldset": {
                            border: "2px solid #002846",
                          },
                        },
                      }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    onChange={handlePasswordInput}
                    value={password}
                    sx={{
                      "input": { color: "#002846", fontFamily: "Open Sans" },
                      "& .MuiInputLabel-root": { color: "#002846", fontFamily: "Open Sans", fontWeight: 700 },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "2px solid #002846" },
                        "&:hover fieldset": { border: "2px solid #002846" },
                        "&.Mui-focused fieldset": {
                          border: "2px solid #002846",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  mt: 2,
                }}
              >
                <Button
                  disabled={is_loading}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={submitForm}
                  sx={{
                    mt: 3,
                    ml: 1,
                    pl: 5,
                    pr: 5,
                    textTransform: "initial",
                  }}
                  endIcon={<ArrowRightIcon />}
                >
                  Login
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                gutterBottom
                align="left"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#002846",
                  textAlign: "center",
                  pb: 1,
                  pt: 2,
                }}
              >
                Coming soon
              </Typography>
            </>
          )}
        </Card>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={severitySnack}
            sx={{ width: "100%" }}
          >
            {messageSnack}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Auth;
