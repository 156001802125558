import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../views/fallback-view/FallbackView'

export function PrivateRoutes() {
  const Rica = lazy(() => import('../views/rica'))
  const SalesReport = lazy(() => import('../views/salesReport'))

  const selfRica = lazy(() => import('../views/self-rica'))
  

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/sales-report' component={SalesReport} />
        <Route path='/rica' component={Rica} />
        <Route path='/self-rica' component={selfRica} />
        <Redirect exact from='/' to='/rica' />
        <Redirect exact from='/auth' to='/rica' />
      </Switch>
    </Suspense>
  )
}
