import { createTheme } from '@mui/material/styles';
import { generatePalette } from './colorUtils';

const createDistributorTheme = (primaryColor, secondaryColor) => {
  return createTheme({
    palette: {
      primary: generatePalette(primaryColor),
      secondary: generatePalette(secondaryColor),
    },
  });
};

export default createDistributorTheme;
