import { createTheme } from "@mui/material/styles";
import VAGRoundedBlack from '../resources/fonts/VAGRounded-Black.woff'
import VAGRoundedBlack2 from '../resources/fonts/VAGRounded-Black.woff2';

const OpenSans = "'Open Sans', sans-serif";

const mainTheme = createTheme({
  palette: {
    primary: {
      light: "#f7f7f7",
      main: "#002846",
      lighter: "#5f8eb2",
      darker: "#00001a",
      dark: "#021623",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1fe481",
      lighter: "#9ad2ac",
      light: "#7fd9a9",
      dark: "#007f2c",
      darker: "#003f16",
      contrastText: "#fff",
   
    },
    info: {
      main: "#f7f7f7",
      lighter: "#4c6f8a",
      light: "#7fb5e1",
      dark: "#002846",
      darker: "#00001a",
      contrastText: "#002846",
    },
    warning: {
      main: "#9f0041",
      lighter: "#c77b9e",
      light: "#cc7a99",
      dark: "#460028",
      darker: "#1a0014",
      contrastText: "#fff",
    },
    success: {
      lighter: "#9ad2ac",
      light: "#7fd9a9",
      main: "#00a152",
      dark: "#007f2c",
      darker: "#003f16",
      contrastText: "#fff",
    },
    error: {
      lighter: "#da8184",
      light: "#f74d53",
      main: "#a30008",
      dark: "#4d0003",
      darker: "#1a0000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["VAG Rounded", OpenSans].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            html, body, #root {
          min-height: 100vh;
          background-color: #FFFFFF;
        }
        @font-face {
          font-family: "VAG Rounded";
          src: url(${VAGRoundedBlack2}) format('woff2'),
              url(${VAGRoundedBlack}) format('woff');
          font-style: normal;
          font-display: swap;
        },
      `,
    },
    MuiButton: {  
      styleOverrides: {
        root: {  
          fontFamily: ["VAG Rounded", OpenSans].join(","), 
          fontWeight: 100,
          borderRadius: "0.5rem",
        },
      },
    },
  },
});
export default mainTheme;
