import * as Sentry from "@sentry/react";
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export const login = async (username, password, partner, dispatch) =>  {
    try {
        let post_data = {
            "username": username,
            "password": password,
        }
    
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
       let {data}  = await axios.post(`${API_URL}/api/v1/distributor/login`, post_data, config);

    //    console.log('response', data)

       if(data.status_code === 1001 ) {
        let user = data.data.user
        let access_token = data.data.token

        // console.log('user', user)

        if(partner && partner === 'honey') {
            dispatch({ type: 'SET_USER', payload: user})
            dispatch({ type: 'SET_ACCESS_TOKEN', payload: access_token})
        } else {
            dispatch({ type: 'SET_USER', payload: user})
            dispatch({ type: 'SET_ACCESS_TOKEN', payload: access_token})

            if(user.brand_id === 'purplemobile') {
                dispatch({ type: "SET_EXTERNAL_ID", payload: 'PURPLE MOBILE' })
            }
            
        }
        
        return {};

       } else {
        return null
       }

    } catch (ex) {
        Sentry.captureException(ex);
        console.error(ex)
        console.error(ex.message)
        return {status_code: 9000, data: ex.message}
    }


};
