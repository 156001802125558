const initialState = {
    is_porting: false,
    port_donor_msisdn: null,
    port_donor_network: "",
    selected_payment_method: "cash",
    selected_identification_method: 1,
    is_valid_customer: false,
    valid_postal_code: false,
    personal_details: {
        gender: 1,
        id_type: 1
    },
    identification_details: {},
    address_details: {},
    identification_document: [],
    poa_document: [],
    sim_serial: "",
    is_valid_sim: false,
    rica_response: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'setSelectedIdentificationMethodCache':{
            return {
                ...state,
                selected_identification_method: action.payload,
            }
        }
        case "setValidCustomerCache":{
            return {
                ...state,
                is_valid_customer: action.payload,
            }
        }
        case "setPersonalDetailsCache":{
            return {
                ...state,
                personal_details: action.payload,
            }
        }
        case "setAddressDetailsCache":{
            return {
                ...state,
                address_details: action.payload,
            }
        }
        case "setIdentificationDetailsCache":{
            return {
                ...state,
                identification_details: action.payload,
            }
        }
        case "setValidPostalCodeCache":{
            return {
                ...state,
                valid_postal_code: action.payload,
            }
        }
        case "setIdDocumentCache":{
            return {
                ...state,
                identification_document: action.payload,
            }
        }
        case "setPoaDocumentCache":{
            return {
                ...state,
                poa_document: action.payload,
            }
        }
        case "setSimSerialCache":{
            return {
                ...state,
                sim_serial: action.payload,
            }
        }
        case "setValidSimCache":{
            return {
                ...state,
                is_valid_sim: action.payload,
            }
        }
        case "setIsPortingCache":{
            return {
                ...state,
                is_porting: action.payload,
            }
        }
        case "setPortDonorMsisdnCache":{
            return {
                ...state,
                port_donor_msisdn: action.payload,
            }
        }
        case "setPortDonorNetworkCache":{
            return {
                ...state,
                port_donor_network: action.payload,
            }
        }
        case "setRicaResponse":{
            return {
                ...state,
                rica_response: action.payload,
            }
        }
        
        default: {
            return state;
        }
    }
};