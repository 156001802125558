import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

//redux for storing token
import { Provider } from 'react-redux';
import { persistor, store } from './setup/store';
import { setupAxios } from './setup';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios'

setupAxios(axios, store)
Sentry.init({
  dsn: "https://dbdfe1101f99494fad2cb5584b138c81@o1420178.ingest.sentry.io/4505017756942336",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
