export default function setupAxios(axios, store) {

    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
      (config) => {
        const {
          auth: {access_token},
        } = store.getState()
        
        if (access_token) {
          config.headers.Authorization = `Bearer ${access_token}`
          config.headers.Accept = 'application/json'
        } else {
          config.headers.Accept = 'application/json'
        }
  
        return config
      },
      (err) => Promise.reject(err)
    )
    
  }