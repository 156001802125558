const initialState = {
    user: {},
    access_token: null,
    refresh_token: null,
    external_id: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SET_ACCESS_TOKEN':
            return {
                ...state,
                access_token: action.payload
            }
        case 'SET_REFRESH_TOKEN':
            return {
                ...state,
                refresh_token: action.payload
            }
        case 'SET_EXTERNAL_ID':
            return {
                ...state,
                external_id: action.payload
            }
        default:
            return state
    }
}