/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Auth from '../views/auth';
import { PrivateRoutes } from './PrivateRoutes';
 
 
 const Routes  = () => {

     const {access_token, user} = useSelector(state => ({ 
                access_token: state.auth.access_token,
                user: state.auth.user
            }), shallowEqual);
     return (
         <>
             <Switch>
                 {!access_token ? (
                     /*Render auth page when user at `/auth` and not authorized.*/
                     <Route>
                         <Auth/>
                         <Redirect to='/auth'/>
                     </Route>
                 ) : (
                    user && Object.keys(user).length !== 0 && 
                     <Redirect from='/auth' to='/rica'/>
                 )}
 
                 {/* <Route path='/error' component={ErrorsPage}/>
                 <Route path='/logout' component={Logout}/>
                 <Route path='/logout' component={Logout}/> */}
 
                 {!access_token ? (
                     /*Redirect to `/auth` when user is not authorized*/
                     <Redirect to='/auth'/>
                 ) : (
                     <>     
                        <PrivateRoutes/>  
                     </>
                 )}
             </Switch>
             
         </>
     )
 }
 
 export { Routes };
 