import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Layout/Header/Header";
import { Routes } from "./routing/Routes";
import { Provider } from "react-redux";
import { store } from "./setup/store.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CssBaseline } from "@mui/material";
import mainTheme from "./themes/mainTheme"; 
import "./App.css";


const MUI_LICENCE_KEY = process.env.REACT_APP_MUI_X_LICENCE_KEY;

LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

function App() {
  return (
    <Provider store={store}>
      <div className="App" role="main">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <ThemeProvider theme={mainTheme}>
              <CssBaseline />
              <Header />
              <Routes />
            </ThemeProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </div>
    </Provider>
  );
}

export default App;
